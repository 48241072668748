import React from 'react';
import MapWithOverlay from './MapWithOverlay';

function App() {
  return (
    <div className="App">
      <MapWithOverlay />
    </div>
  );
}

export default App;
