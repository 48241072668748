import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, GroundOverlay } from '@react-google-maps/api';
import { getLatestImageUrls } from './utils/getLatestImageUrls';
import './App.css'; // Import the CSS file

const calculateMinutesSince = (timestamp) => {
  const dateStr = timestamp;
  const imageDate = new Date(
    Date.UTC(
      parseInt(dateStr.substring(0, 4)), // Year
      parseInt(dateStr.substring(4, 6)) - 1, // Month (zero-indexed)
      parseInt(dateStr.substring(6, 8)), // Day
      parseInt(dateStr.substring(8, 10)), // Hour
      parseInt(dateStr.substring(10, 12)) // Minute
    )
  );

  const nowSGT = new Date();
  const SGT_OFFSET = 8 * 60 * 60 * 1000; // Singapore Time is UTC+8
  const nowSGTTime = new Date(nowSGT.getTime() + SGT_OFFSET);

  const diffMs = nowSGTTime - imageDate;
  const diffMins = Math.floor(diffMs / 60000); // Convert milliseconds to minutes

  return diffMins;
};

const MapWithOverlay = () => {
  const [overlayUrls, setOverlayUrls] = useState([]);
  const [currentOverlayIndex, setCurrentOverlayIndex] = useState(0);

  useEffect(() => {
    const urls = getLatestImageUrls();
    console.log('Generated URLs:', urls); // Log URLs to check their validity
    setOverlayUrls(urls);
    setCurrentOverlayIndex(urls.length > 0 ? urls.length-1 : 0)
  }, []); // Ensure this runs only once on mount

  const handleSliderChange = (event) => {
    const newIndex = parseInt(event.target.value, 10);
    setCurrentOverlayIndex(newIndex);
    console.log('Current Image URL:', overlayUrls[newIndex]); // Log the URL of the currently selected image
  };

  const handleImageLoadError = (url) => {
    console.error(`Failed to load image: ${url}`);
  };

  const mapContainerStyle = {
    width: '100vw',
    height: '100vh',
  };

  const center = React.useMemo(() => ({
    lat: 1.345801,
    lng: 103.86729,
  }), []);  // This memoizes the center object so it doesn't cause rerenders
  
  // const bounds = { // old rainlah bounds for different images
  //   north: 1.978634136034281, // New North-East Latitude
  //   south: 0.7195858639657194, // New South-West Latitude
  //   east: 104.60227869801894, // New North-East Longitude
  //   west: 103.34288130198104, // New South-West Longitude
  // };

  const imageBounds = { // correct bounds
    north: 1.475,
    south: 1.1450,
    east: 104.130,
    west: 103.565,
  };

  const currentImageUrl = overlayUrls[currentOverlayIndex];
  const timestampMatch = currentImageUrl ? currentImageUrl.match(/(\d{12})0000dBR\.dpsri\.png$/) : null;
  const timestamp = timestampMatch ? timestampMatch[1] : '';
  const minutesSince = timestamp ? calculateMinutesSince(timestamp) : 'N/A';

  return (
    <LoadScript googleMapsApiKey="AIzaSyDb208f6SHblepnqcR51IwCvpvs5Twhym8">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={11} // Set the zoom level
        options={{ 
          disableDefaultUI: true, // Disable all default UI elements
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
          gestureHandling: 'greedy',
        }}
      >
        {overlayUrls.map((url, index) => (
          <GroundOverlay
            key={url}
            url={url}
            bounds={imageBounds} // Use the updated bounds
            opacity={index === currentOverlayIndex ? 0.6 : 0} // Ensure only the current image is visible
            onLoad={() => console.log(`Loaded image: ${url}`)} // Log successful image load
            onError={() => handleImageLoadError(url)} // Handle image load errors
          />
        ))}
        {overlayUrls.length > 0 && (
          <>
            <div className="slidecontainer">
              <input className="slider"
                type="range"
                min="0"
                max={overlayUrls.length - 1}
                value={currentOverlayIndex}
                onChange={handleSliderChange}
              />
            </div>
            <div className="time-display">
              <div>{minutesSince} minutes ago</div>
            </div>
          </>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapWithOverlay;
