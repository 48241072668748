export function getLatestImageUrls() {
  const currentTime = new Date();
  
  // Adjust to Singapore Time (SGT, UTC+8)
  const singaporeTime = new Date(currentTime.getTime() + 8 * 60 * 60 * 1000);

  const imageUrls = [];

  // Generate URLs for the last 5 intervals (5 minutes each)
  for (let i = 0; i < 7; i++) {
    // Calculate the time for each interval
    const time = new Date(singaporeTime.getTime() - i * 5 * 60000);

    const year = time.getUTCFullYear();
    const month = String(time.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(time.getUTCDate()).padStart(2, '0');
    const hour = String(time.getUTCHours()).padStart(2, '0');
    const minute = String(Math.floor(time.getUTCMinutes() / 5) * 5).padStart(2, '0'); // Round to nearest 5 minutes

    // Construct the URL with a cache-busting parameter
    const url = `https://www.weather.gov.sg/files/rainarea/50km/v2/dpsri_70km_${year}${month}${day}${hour}${minute}0000dBR.dpsri.png`;
    imageUrls.push(url);
  }

  console.log('Generated Image URLs:', imageUrls); // Log URLs to check their validity
  return imageUrls.reverse(); // Reverse to get the oldest first
}
